@import url(https://fonts.googleapis.com/css?family=Lato:300,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Niconne);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@charset "UTF-8";
/*!
 * Vali Admin
 * Author: Pratik Borsadiya
 * ================================*/

/*----- Componant: Top Navigation Bar ----- */
body {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

.app-content {
  min-height: calc(100vh - 50px);
  margin-top: 50px;
  padding: 30px;
  transition: margin-left 0.3s ease;
}


.app-nav,
.app-menu,
.treeview-menu {
  padding-left: 0;
  list-style: none;
}

.app-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: -ms-flexbox;
  height: 50px;
  display: flex;
  background-color: #000;
  z-index: 1030;
  padding-right: 15px;
  justify-content: space-between;
}

.app-header__logo {
  flex: 1 0 auto;
  color: #fff;
  text-align: center;
  padding: 0 15px;
  font-size: 26px;
  font-weight: 400;
  line-height: 50px;
}


.app-header__logo:focus,
.app-header__logo:hover {
  text-decoration: none;
  color: #fff;
}

.app-sidebar__toggle {
  padding: 0 15px;
  font-family: fontAwesome;
  color: #1f262d;
  line-height: 2.4;
  transition: background-color 0.3s ease;
}

.app-sidebar__toggle:before {
  content: "\f0c9";
  font-size: 21px;
}

.app-sidebar__toggle:focus,
.app-sidebar__toggle:hover {
  color: #fff;
  background-color: #00635a;
  text-decoration: none;
}

.app-nav {
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
}

.app-nav__item {
  display: block;
  padding: 15px;
  line-height: 20px;
  color: #fff;
  transition: background-color 0.3s ease;
}

.app-nav__item:hover,
.app-nav__item:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

.app-search {
  position: relative;
  display: flex;
  align-self: center;
  margin-right: 15px;
  padding: 10px 0;
}

.app-search__input {
  border: 0;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.3s ease;
}

.app-search__input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.app-search__input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.app-search__input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.app-search__button {
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  padding: 0 10px;
  border: 0;
  color: rgba(0, 0, 0, 0.8);
  background: none;
  cursor: pointer;
}

.app-notification {
  min-width: 270px;
}

.app-notification__title {
  padding: 8px 20px;
  text-align: center;
  background-color: rgba(0, 150, 136, 0.4);
  color: #333;
}

.app-notification__footer {
  padding: 8px 20px;
  text-align: center;
  background-color: #eee;
}

.app-notification__content {
  max-height: 220px;
  overflow-y: auto;
}

.app-notification__content::-webkit-scrollbar {
  width: 6px;
}

.app-notification__content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.app-notification__item {
  display: flex;
  padding: 8px 20px;
  color: inherit;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.app-notification__item:focus,
.app-notification__item:hover {
  color: inherit;
  text-decoration: none;
  background-color: #e0e0e0;
}

.app-notification__message,
.app-notification__meta {
  margin-bottom: 0;
}

.app-notification__icon {
  padding-right: 10px;
}

.app-notification__message {
  line-height: 1.2;
}

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding-top: 51px;
  width: 230px;
  overflow: auto;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
  transition: left 0.3s ease, width 0.3s ease;
}

.app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}


.app-sidebar__user {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
}

.app-sidebar__user-avatar {
  flex: 0 0 auto;
  margin-right: 15px;
}

.app-sidebar__user-name {
  font-size: 17px;
  line-height: 1.3;
}

.app-sidebar__user-name,
.app-sidebar__user-designation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.app-menu {
  margin-bottom: 0;
  padding-bottom: 40px;
}

.app-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  font-size: 1.08em;
  border: none;
  border-right: 5px solid transparent;
  transition: border-right-color 0.3s ease, background-color 0.3s ease;
  color: #000;
}

.app-menu__item.active,
.app-menu__item:hover,
.app-menu__item:focus {
  background: #e31e24;
  border-right-color: rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #fff;
}

.app-menu__icon {
  flex: 0 0 auto;
  margin-right: 0.5rem;
}

.app-menu__item.submenu {
  padding: 12px 25px;
}

.app-menu__label {
  white-space: nowrap;
  flex: 1 1 auto;
}

.treeview.is-expanded [data-toggle="treeview"] {
  border-left-color: #009688;
  background: #0d1214;
}

.treeview.is-expanded .treeview-menu {
  max-height: 100vh;
}

.treeview.is-expanded .treeview-indicator {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.treeview-menu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background: #2a383e;
}

.treeview-item {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 20px;
  font-size: 1em;
  color: #fff;
}

.treeview-item.active,
.treeview-item:hover,
.treeview-item:focus {
  background: #0d1214;
  text-decoration: none;
  color: #fff;
}

.treeview-item .icon {
  margin-right: 5px;
}

.treeview-indicator {
  -webkit-transform-origin: center;
  transform-origin: center;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.dropdown-menu {
  z-index: 1000;
  min-width: 10rem;
  padding: 0 0;
  margin: 0 0 0;
  font-size: 0.875rem;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.dropdown-menu.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-item .fa,
.dropdown-item .icon {
  margin-right: 5px;
  vertical-align: middle;
}

.btn {
  cursor: pointer;
}

.btn:not([disabled]):not(.disabled):not(.btn-link):hover,
.btn:not([disabled]):not(.disabled):not(.btn-link):focus {
  text-decoration: none;
  /* -webkit-transform: translate3d(0, -1px, 0);
  transform: translate3d(0, -1px, 0);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); */
}


.btn[disabled] {
  cursor: not-allowed;
}

.btn .icon,
.btn .fa {
  font-size: 14px;
  margin-right: 5px;
  vertical-align: middle;
}

.app-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  /* background-color: #fff; */
  margin: -30px -30px 0px;
  padding: 20px 30px;
}


/* @media (max-width: 480px) {
  .app-title {
    margin: -15px -15px 15px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
} */

.app-title h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
}

.app-breadcrumb {
  margin-bottom: 0;
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: left;
  padding: 0;
  background-color: transparent;
}



.tile {
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}

.tile-body {
  width: 100%;
  /* overflow-x: auto; */
}

.tile.small {
  padding: 0;
}

.tile .overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: #555;
  border-radius: 3px;
}

.tile .overlay .l-text {
  font-size: 24px;
  margin-top: 15px;
}

.tile .tile-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.tile .tile-title-w-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tile .tile-title-w-btn p {
  margin: 0;
}

.tile .tile-title-w-btn .title {
  margin: 0;
}

.tile .tile-title-w-btn .side {
  margin-bottom: 0;
  padding-left: 0;
}

.tile .tile-title-w-btn .btn-group {
  flex: 0 0 auto;
}

.tile .tile-footer {
  border-top: 1px solid #ddd;
  padding: 20px 0 0 0;
  margin-top: 10px;
}

/* Custom Checkbox and Radio Buttons */

.animated-checkbox input[type="checkbox"] {
  display: none;
}

.animated-checkbox input[type="checkbox"]+.label-text {
  cursor: pointer !important;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.animated-checkbox input[type="checkbox"]+.label-text:before {
  content: "";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 2px;
  vertical-align: -2px;
}

.animated-checkbox input[type="checkbox"]:checked+.label-text:before {
  content: "";
  color: #009688;
  -webkit-animation: tick 180ms ease-in;
  animation: tick 180ms ease-in;
}

.animated-checkbox input[type="checkbox"]:disabled+.label-text {
  cursor: not-allowed !important;
}

.animated-checkbox input[type="checkbox"]:disabled+.label-text:before {
  content: "";
  color: #ccc;
}

.animated-radio-button input[type="radio"] {
  display: none;
}

.animated-radio-button input[type="radio"]+.label-text {
  cursor: pointer !important;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.animated-radio-button input[type="radio"]+.label-text:before {
  content: "";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  width: 1em;
  display: inline-block;
  margin-right: 2px;
  vertical-align: -2px;
}

.animated-radio-button input[type="radio"]:checked+.label-text:before {
  content: "";
  color: #009688;
  -webkit-animation: tick 180ms ease-in;
  animation: tick 180ms ease-in;
}

.animated-radio-button input[type="radio"]:disabled+.label-text {
  cursor: not-allowed !important;
}

.animated-radio-button input[type="radio"]:disabled+.label-text:before {
  content: "";
  color: #ccc;
}

@-webkit-keyframes tick {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  90% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes tick {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  90% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.toggle.lg input[type="checkbox"]+.button-indecator:before {
  font-size: 30px;
}

.toggle input[type="checkbox"] {
  display: none;
}

.toggle input[type="checkbox"]+.button-indecator {
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle input[type="checkbox"]+.button-indecator:before {
  content: "";
  font-family: "FontAwesome";
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 25px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  margin-right: 5px;
  vertical-align: -2px;
}

.toggle input[type="checkbox"]:checked+.button-indecator:before {
  content: "";
  color: #009688;
  -webkit-animation: toggleBtn 0.3s ease-in-out;
  animation: toggleBtn 0.3s ease-in-out;
}

.toggle input[type="checkbox"]:disabled+.button-indecator {
  cursor: not-allowed !important;
}

.toggle input[type="checkbox"]:disabled+.button-indecator:before {
  color: #ccc;
}

.toggle-flip input[type="checkbox"] {
  display: none;
}

.toggle-flip input[type="checkbox"]+.flip-indecator {
  position: relative;
  width: 60px;
  height: 30px;
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-perspective: 90px;
  perspective: 90px;
}

.toggle-flip input[type="checkbox"]+.flip-indecator:before,
.toggle-flip input[type="checkbox"]+.flip-indecator:after {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  line-height: 30px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  border-radius: 3px;
  transition: all 0.5s ease;
}

.toggle-flip input[type="checkbox"]+.flip-indecator:before {
  content: attr(data-toggle-off);
  background-color: #ddd;
}

.toggle-flip input[type="checkbox"]+.flip-indecator:after {
  content: attr(data-toggle-on);
  background-color: #009688;
  color: #fff;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.toggle-flip input[type="checkbox"]:checked+.flip-indecator:before {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.toggle-flip input[type="checkbox"]:checked+.flip-indecator:after {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.toggle-flip input[type="checkbox"]:disabled+.flip-indecator {
  cursor: not-allowed !important;
  color: #ccc;
}

@-webkit-keyframes toggleBtn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes toggleBtn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.material-half-bg {
  height: 100vh;
  background-color: #e7e7e7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.material-half-bg .cover {
  background-color: #009688;
  height: 50vh;
}

.m-loader {
  position: relative;
  width: 40px;
}

.m-loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.m-circular {
  -webkit-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: #009688;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.btn-spinner {
  display: inline-block;
  z-index: 2000;
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-right: 5px;
  border: solid 2px transparent;
  border-top-color: #fff;
  border-left-color: #fff;
  border-radius: 10px;
  -webkit-animation: bSpinner 0.8s linear infinite;
  animation: bSpinner 0.8s linear infinite;
}

@-webkit-keyframes bSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes bSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.widget-small {
  display: flex;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 30px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.widget-small .icon {
  display: flex;
  min-width: 85px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;
  font-size: 2.5rem;
}

.widget-small .info {
  flex: 1 1;
  padding: 0 20px;
  align-self: center;
  word-break: break-all;
}

.widget-small .info h4 {
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 1.1rem;
}

.widget-small .info p {
  margin: 0;
  font-size: 16px;
}

.widget-small.primary {
  background-color: #009688;
}

.widget-small.primary.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.primary.coloured-icon .icon {
  background-color: #009688;
  color: #fff;
}

.widget-small.info {
  background-color: #17a2b8;
}

.widget-small.info.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.info.coloured-icon .icon {
  background-color: #17a2b8;
  color: #fff;
}

.widget-small.warning {
  background-color: #ffc107;
}

.widget-small.warning.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.warning.coloured-icon .icon {
  background-color: #ffc107;
  color: #fff;
}

.widget-small.danger {
  background-color: #dc3545;
}

.widget-small.danger.coloured-icon {
  background-color: #fff;
  color: #2a2a2a;
}

.widget-small.danger.coloured-icon .icon {
  background-color: #dc3545;
  color: #fff;
}

.messanger {
  display: flex;
  flex-direction: column;
}

.messanger .messages {
  flex: 1 1;
  margin: 10px 0;
  padding: 0 10px;
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
}

.messanger .messages .message {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-start;
}

.messanger .messages .message.me {
  flex-direction: row-reverse;
}

.messanger .messages .message.me img {
  margin-right: 0;
  margin-left: 15px;
}

.messanger .messages .message.me .info {
  background-color: #009688;
  color: #fff;
}

.messanger .messages .message.me .info:before {
  display: none;
}

.messanger .messages .message.me .info:after {
  position: absolute;
  right: -13px;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 0;
  border-color: transparent #009688 transparent transparent;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.messanger .messages .message img {
  border-radius: 50%;
  margin-right: 15px;
}

.messanger .messages .message .info {
  margin: 0;
  background-color: #ddd;
  padding: 5px 10px;
  border-radius: 3px;
  position: relative;
  align-self: flex-start;
}

.messanger .messages .message .info:before {
  position: absolute;
  left: -14px;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 0;
  border-color: transparent #ddd transparent transparent;
}

.messanger .sender {
  display: flex;
}

.messanger .sender input[type="text"] {
  flex: 1 1;
  border: 1px solid #009688;
  outline: none;
  padding: 5px 10px;
}

.messanger .sender button {
  border-radius: 0;
}

.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.login-content .logo {
  margin-bottom: 40px;
  font-family: "Niconne";
  color: #fff;
}

.login-content .logo h1 {
  font-size: 52px;
  font-weight: 400;
}

.login-content .login-box {
  position: relative;
  min-width: 350px;
  min-height: 390px;
  background-color: #fff;
  box-shadow: 0px 29px 147.5px 102.5px rgba(0, 0, 0, 0.05),
    0px 29px 95px 0px rgba(0, 0, 0, 0.16);
  -webkit-perspective: 800px;
  perspective: 800px;
  transition: all 0.5s ease-in-out;
}

.login-content .login-box .login-head {
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

.login-content .login-box label {
  color: #666;
  font-weight: 700;
}

.login-content .login-box .utility {
  display: flex;
  padding: 1px;
  justify-content: space-between;
  align-items: center;
}

.login-content .login-box .btn-container {
  margin-bottom: 0;
}

.login-content .login-box .login-form,
.login-content .login-box .forget-form {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.login-content .login-box .forget-form {
  opacity: 0;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.login-content .login-box.flipped {
  min-height: 300px;
}

.login-content .login-box.flipped .login-form {
  opacity: 0;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.login-content .login-box.flipped .forget-form {
  opacity: 1;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}


.lockscreen-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.lockscreen-content .logo {
  font-family: "Niconne";
  margin-bottom: 40px;
  color: #fff;
}

.lockscreen-content .logo h1 {
  font-size: 52px;
  font-weight: 400;
}

.lockscreen-content .lock-box {
  position: relative;
  min-width: 320px;
  padding: 20px 40px;
  background-color: #fff;
  box-shadow: 0px 29px 147.5px 102.5px rgba(0, 0, 0, 0.05),
    0px 29px 95px 0px rgba(0, 0, 0, 0.16);
  -webkit-perspective: 800px;
  perspective: 800px;
  transition: all 0.5s ease-in-out;
}

.lockscreen-content .lock-box .user-image {
  border: 3px solid #fff;
  max-width: 100px;
  display: block;
  margin: 0 auto;
}

.lockscreen-content .lock-box .user-name {
  margin-bottom: 2px;
}

.lockscreen-content .lock-box label {
  color: #666;
  font-weight: 700;
}

.page-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 110px);
  margin-bottom: 0;
}

.page-error h1 {
  margin: 10px;
  color: #dc3545;
  font-size: 42px;
}

.folder-head {
  padding: 10px;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #ddd;
}

.mailbox-controls {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding: 0 0 10px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mailbox-controls .animated-checkbox .label-text:before {
  vertical-align: -7px !important;
}

.mail-nav>li>a {
  display: block;
  background-color: #fff;
  color: #2a2a2a;
  border-radius: 0;
  font-weight: 500;
}

.mailbox-messages table tr td {
  border: 0;
  padding: 5px;
}

.mailbox-messages .mail-subject {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
}

.user .profile {
  margin: -30px -30px 0 -30px;
  display: flex;
}

.user .profile .info {
  padding: 30px 60px;
  text-align: center;
  background-color: #fff;
  white-space: nowrap;
  background-color: #404040;
  color: #fff;
}

.user .profile .info img {
  border-radius: 50%;
  max-width: 110px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.user .profile .cover-image {
  flex: 1 1;
  background-image: url(http://placeimg.com/1200/300/nature);
  background-size: cover;
  background-position: center;
  min-height: 300px;
}

.user .user-tabs {
  margin-top: 20px;
}

.user .user-tabs .nav-link {
  border-left: 3px solid transparent;
  padding: 12px 15px;
  border-bottom: 0;
  font-size: 15px;
  border-radius: 0;
}

.user .user-tabs .nav-link.active {
  border-left-color: #009688;
  border-bottom: 0;
}

.user .user-tabs .nav-link:hover,
.user .user-tabs .nav-link:active {
  background-color: #eee !important;
  border-bottom: 0;
}

.user .tab-content {
  margin: 20px -10px -10px;
}

.user .timeline-post {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.user .timeline-post .post-media {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.user .timeline-post .post-media img {
  margin-right: 10px;
}

.user .timeline-post .post-media h5 {
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 15px;
}

.user .timeline-post .post-content {
  margin-bottom: 20px;
}

.user .timeline-post .post-utility {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.user .timeline-post .post-utility .likes,
.user .timeline-post .post-utility .shares {
  margin-right: 10px;
}

.user .timeline-post .post-utility .likes a,
.user .timeline-post .post-utility .shares a {
  color: #555;
}

.user .timeline-post .post-utility .comments {
  color: #555;
  flex: 1 1;
  text-align: right;
}

.user .user-settings .line-head {
  font-size: 18px;
  margin-bottom: 30px;
  color: #2a2a2a;
}

.user .user-settings label {
  color: #666;
}

/* body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #fff !important;
} */
.user_card {
  height: 55%;
  width: 35%;
  margin-top: 20%;
  margin-bottom: auto;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}


.brand_logo_container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background: #60a3bc;
  padding: 10px;
  text-align: center;
}

.brand_logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid white;
}


.login_btn {
  width: 100%;
  background: #2874f0 !important;
  color: white !important;
}

.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.login_container {
  padding: 0 2rem;
}

.input-group-text {
  background: #2874f0 !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #c0392b !important;
}

.bg-grey {
  background-color: #dadada !important;
}

.admin-dropdown {
  background-color: transparent;
  border: none;
  border-radius: 0;
  height: 100%;
}

.admin-dropdown:hover {
  background-color: #007bff;
}

.show>.btn-primary.admin-dropdown.dropdown-toggle {
  background-color: #e31e24;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin: 3rem 0;
}

.breadcrumbs li a {
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.8rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}

.back span {
  width: 2.8rem;
  height: 2.8rem;
  background: #fff;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.08);
  border-radius: 0.8rem;
  display: flex;
  margin-right: 0.8rem;
  align-items: center;
  justify-content: center;
}

.back {
  border: none;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.action-dropdown {
  border: none;
  background-color: #fff;
  padding: 0;
  margin: 0;
  min-width: 1rem !important;
}

.icon {
  color: #000;
}

.action-dropdown:hover {
  background-color: #fff;
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  border-color: #fff;
}

.action-dropdown:focus {
  background-color: #fff;
  border-color: #fff;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  background-color: #fff;
  border-color: #fff;
  border-top-color: #fff;
}

.btn:not([disabled]):not(.disabled):not(.btn-link):hover,
.btn:not([disabled]):not(.disabled):not(.btn-link):focus {
  -webkit-box-shadow: none;
  border-color: #fff;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: none;
}

.btn {
  /* min-width: 5rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-drawer {
  position: absolute;
  right: 0;
  top: 10px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  height: 20rem;
}

.page-section {
  border: 1px dotted;
  border-color: #cecece;
}

.icon-button {
  position: absolute;
  top: 0;
  right: 17px;
  font-size: 1.5rem;
}

.icon-button:hover {
  cursor: pointer;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  background-color: #eaeaea;
  /* border-bottom: 2px solid #dee2e6; */
}

@media (max-width: 351px) {
  .login-content .login-box {
    min-width: 100%;
  }

  .login-content .login-box .login-form,
  .login-content .login-box .forget-form {
    width: 100%;
  }
}


@media (max-width: 576px) {
  .user_card {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-top: 10px;
  }

  .app-content {
    padding: 15px;
  }

  .app-search {
    display: none;
  }
}

@media (min-width: 768px) {
  .app-content {
    margin-left: 230px;
  }

  .app-header {
    padding-right: 30px;
  }

  .app-header__logo {
    flex: 0 0 auto;
    display: block;
    width: 230px;
    background-color: #fff;
  }

  .app-nav {
    flex: 1 0 auto;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__user-name,
  .sidebar-mini.sidenav-toggled .app-sidebar__user-designation,
  .sidebar-mini.sidenav-toggled .treeview-indicator {
    display: none;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar__user-avatar {
    width: 30px;
    height: 30px;
  }

  .sidebar-mini.sidenav-toggled .app-content {
    margin-left: 50px;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar {
    left: 0;
    width: 50px;
    overflow: hidden;
  }

  .sidebar-mini.sidenav-toggled .app-sidebar:hover {
    overflow: visible;
  }

  .sidebar-mini.sidenav-toggled .app-menu__item {
    overflow: hidden;
  }

  .sidebar-mini.sidenav-toggled .app-menu__item:hover {
    overflow: visible;
  }

  .sidebar-mini.sidenav-toggled .app-menu__item:hover .app-menu__label {
    opacity: 1;
  }

  .sidebar-mini.sidenav-toggled .app-menu__item:hover+.treeview-menu {
    visibility: visible;
  }

  .sidebar-mini.sidenav-toggled .app-menu__label {
    display: block;
    position: absolute;
    top: 0;
    left: 50px;
    min-width: 180px;
    padding: 12px 10px;
    margin-left: 0;
    line-height: 1;
    opacity: 0;
    background: #0d1214;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .sidebar-mini.sidenav-toggled .treeview:hover .app-menu__item {
    overflow: visible;
    background: #0d1214;
    border-left-color: #009688;
    color: #fff;
  }

  .sidebar-mini.sidenav-toggled .treeview:hover .app-menu__label {
    opacity: 1;
  }

  .sidebar-mini.sidenav-toggled .treeview:hover .treeview-menu {
    max-height: 100vh;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  .sidebar-mini.sidenav-toggled .treeview .app-menu__label {
    border-bottom-right-radius: 0;
  }

  .sidebar-mini.sidenav-toggled .treeview-menu {
    position: absolute;
    left: 50px;
    min-width: 180px;
    padding: 12px 0;
    opacity: 0;
    border-bottom-right-radius: 4px;
    z-index: 9;
    visibility: hidden;
    transition: visibility 0.3s ease;
  }
}

@media (max-width: 767px) {
  .app {
    overflow-x: hidden;
  }

  .app .app-sidebar {
    left: -230px;
  }

  .app .app-sidebar__overlay {
    visibility: hidden;
  }

  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }

  .app.sidenav-toggled .app-sidebar {
    left: 0;
  }

  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: visible;
  }

  .app-content {
    margin-top: 50px;
    min-width: 100%;
  }

  .app-sidebar__toggle {
    order: -1;
  }

  .app-sidebar__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
}


@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: #fff;
  }

  .app-header {
    display: none;
  }

  .tile {
    border: 1px solid #ddd;
  }

  .app-title {
    display: none;
  }

  .app-sidebar {
    display: none;
  }
}


.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.admin-dropdown:hover,
.admin-dropdown:focus,
.admin-dropdown:active {
  background: #e31e24;
}

.btn-success {
  background: #e31e24;
  border-color: #e31e24;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background: #e31e24;
  border-color: #e31e24;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active {
  background-color: #000;
  border-color: #000 !important;
}

.sidebar-mini.sidenav-toggled .app-menu__item {
  min-width: 1px;
  border-right: none;
}

.sidebar-mini.sidenav-toggled .app-menu button>div>svg {
  display: none;
}

.sidebar-mini.sidenav-toggled .app-menu .app-menu__icon {
  margin-right: 0;
}


.dropdown-item {
  padding: .5rem 1rem;
}

.sidebar-mini.sidenav-toggled .app-header__logo {
  background-color: transparent;
  width: auto;
}

.rstcustom__collapseButton::after {
  display: none;
}

.rstcustom__collapseButton, .rstcustom__expandButton {
  top: 18px;
  width: auto;
  left: 10px;
  height: auto;
}

.rstcustom__rowContents {
  padding: 10px 5px 10px 30px;
}

.sidebar-mini.sidenav-toggled .app-header>div button {
  background: #e31e24 !important;
}

.sidebar-mini.sidenav-toggled .app-menu__icon {
  display: block;
  margin: 0 auto !important;
}

.sidebar-mini.sidenav-toggled .app-menu__item.submenu {
  padding: 12px 15px;
}

.headline-box {
  border: solid 1px lightgray;
}

.custom-file-label {
  z-index: 0;
}

.image-wrapper {
  height: 120px;
  background: #eee;
}

.image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
